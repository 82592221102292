import React, { useEffect, useState } from 'react';
import './App.css';
import Posts from './Posts';
import CreatePost from './CreatePost'; // Добавляем компонент для создания поста
import TelegramAuth from './TelegramAuth';

function App() {
    const [user, setUser] = useState(null);

    useEffect(() => {
        if (window.Telegram?.WebApp) {
            const tg = window.Telegram.WebApp;
            tg.ready();

            // Получаем initData и отправляем на сервер для авторизации
            const initData = tg.initData;
            fetch('https://api.kypito.ru/api/auth', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ initData }), // Передаем initData на сервер
            })
                .then(response => response.json())
                .then(data => {
                    if (data.status === 'ok') {
                        setUser(data.user); // Сохраняем пользователя
                    } else {
                        console.error("Auth error:", data.message);
                    }
                })
                .catch(console.error);

            tg.MainButton.setText("Create Post");
            tg.MainButton.onClick(() => {
                alert("Ready to create a post!");
            });
            tg.MainButton.show();
        }
    }, []);

    return (
        <div className="App">
            <h1>Telegram WebApp Authorization</h1>
            <TelegramAuth />
            <header className="App-header">
                <h1>Welcome {user?.first_name || 'Guest'}</h1>
                <Posts />
                {user && <CreatePost user={user} />} {/* Показываем форму создания поста, если пользователь авторизован */}
            </header>
        </div>
    );
}

export default App;

import React, { useEffect, useState } from 'react';

function TelegramAuth() {
  const [user, setUser] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Проверка, доступна ли WebApp
    if (window.Telegram && window.Telegram.WebApp) {
      const tg = window.Telegram.WebApp;

      // Проверка на наличие initData
      const initData = tg.initData;
      if (!initData) {
        setError('initData is not available in Telegram WebApp.');
        console.error('initData is missing.');
        return;
      }

      // Отправляем данные на сервер для авторизации
      fetch('https://api.kypito.ru/api/auth', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ initData }),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          if (data.status === 'ok') {
            setUser(data.user);
          } else {
            setError(data.message || 'Authorization failed.');
            console.error('Error:', data.message);
          }
        })
        .catch((error) => {
          setError('Failed to fetch or process response.');
          console.error('Fetch error:', error);
        });
    } else {
      setError('Telegram WebApp is not available.');
      console.error('Telegram WebApp is not defined.');
    }
  }, []);

  return (
    <div>
      {error ? (
        <div>
          <h2>Error:</h2>
          <p>{error}</p>
        </div>
      ) : user ? (
        <div>
          <h2>Welcome, {user.first_name}!</h2>
          <p>Your username is {user.username}</p>
        </div>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
}

export default TelegramAuth;

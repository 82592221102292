import React, { useState } from 'react';

const CreatePost = ({ user }) => {
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();

        fetch('https://w.kypito.ru/wp-json/wp/v2/posts', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`,  // Using JWT token for auth
            },
            body: JSON.stringify({
                title: title,
                content: content,
                status: 'publish',  // Set to publish
            }),
        })
            .then(response => response.json())
            .then(data => {
                alert('Post created successfully!');
                setTitle('');
                setContent('');
            })
            .catch(err => alert('Error creating post: ' + err.message));
    };

    return (
        <div>
            <h2>Create a new post</h2>
            <form onSubmit={handleSubmit}>
                <label>
                    Title:
                    <input type="text" value={title} onChange={e => setTitle(e.target.value)} required />
                </label>
                <br />
                <label>
                    Content:
                    <textarea value={content} onChange={e => setContent(e.target.value)} required />
                </label>
                <br />
                <button type="submit">Create Post</button>
            </form>
        </div>
    );
};

export default CreatePost;  // Ensure this is a default export
